<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">App mode</h3>
      </div>
      <el-form ref="form">
        <el-row class="sidemenu">
          <el-col :span="24">
            <div class="card-body">
              Developer mode
              <el-switch
                style="display: block"
                v-model="appMode"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="Off"
                inactive-text="On"
              >
              </el-switch>
              <el-link
                v-if="appMode && this.getAppMode"
                type="primary"
                class="mt-1"
                :underline="false"
                @click="fetchNewchanges"
                >Sync new changes</el-link
              >
              <el-alert
                class="mt-1"
                :title="appMode ? note_on : note_off"
                type="warning"
                :closable="false"
              >
              </el-alert>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="!appMode && !this.getAppMode">
          <el-col :span="24">
            <div class="card-body">
              <span>Troubleshoot</span>
              <el-alert
                class="mt-1"
                title="Not seeing the latest information? Click 'Clear Cache' to reset and update your data for the latest updates!"
                type="info"
                show-icon
                :closable="false"
              >
              </el-alert>
              <el-link
                type="primary"
                class="mt-1"
                :underline="false"
                @click="clearCache"
                >Clear Cache</el-link
              >
            </div>
          </el-col>
        </el-row>
        <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button
              type="primary"
              @click="saveChanges"
              >Save</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      class="plain-header"
    >
      <div class="icon-container">
        <img src="@/assets/img/SyncNewChanges.gif" />
        <p class="text-bold">{{ syncLoadingText }}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setApplicationSetup, clearDB } from "../../repo/appSettingRepo";
export default {
  name: "app-mode-configuration",
  data() {
    return {
      loadingText: "Loading...",
      loading: false,
      appMode: false,
      centerDialogVisible: false,
      syncLoadingText: "Please wait, we are setting things up for you...",
      loadingIntervalId: null,
      note_on:
        "All necessary data will be downloaded to ensure a seamless experience while using the application.",
      note_off:
        "Real-time data retrieval is essential during development to ensure the application always uses the most current information.",
    };
  },
  methods: {
    async clearCache() {
      this.loading = true;
      await clearDB();
      this.$notify.success({
        title: "Success",
        message: "Cleared data successfully",
      });
      this.loading = false;
    },
    getLoadingText() {
      const captions = [
        "Empower your team with low-code solutions.",
        "Build applications faster with low-code platforms.",
        "Low-code: The future of app development.",
        "Reduce development time with low-code.",
        "Transform your business processes with low-code tools.",
        "Accelerate digital transformation using low-code.",
        "Low-code: Simplifying complex workflows.",
        "Innovate quickly with low-code technology.",
      ];
      const randomIndex = Math.floor(Math.random() * captions.length);
      return captions[randomIndex];
    },
    generateRandomCaptions() {
      let that = this;
      this.loadingIntervalId = setInterval(function () {
        that.syncLoadingText = that.getLoadingText();
      }, 1500);
    },
    async fetchNewchanges() {
      this.centerDialogVisible = true;
      this.generateRandomCaptions();
      await setApplicationSetup();
      clearInterval(this.loadingIntervalId);
      this.loadingIntervalId = null;
      this.centerDialogVisible = false;
    },
    async saveChanges() {
      this.$store.commit("auth/setAppMode", this.appMode, {
        root: true,
      });
      this.$notify.success({
        title: "Success",
        message: "Settings saved successfully",
      });
      if (this.appMode) {
        this.fetchNewchanges();
      } else {
        clearDB();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getAppMode"]),
  },
  async mounted() {
    this.appMode = this.getAppMode;
  },
};
</script>

<style scoped lang="scss">
.card-body {
  padding: 25px;
}
.icon-container {
  text-align: center;
}
</style>
